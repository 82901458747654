import { css } from '@emotion/react'
import { Avatar, Button, ButtonLink, Text, TextContainer } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'
import { assert } from '../../lib/errors'
import { useDisconnect } from '../../lib/hooks/use-disconnect'
import { useSession } from '../../lib/session'
import { ActionPageLayout } from './action-page-layout'
import { Page } from './page'

/**
 * Wrapper that prevents a page created for customer to be accessed by orus agents. This is useful
 * to simplify the customer pages by letting them assume that the user has a contract, instead of implementing
 * empty states everywhere, and leading backoffice users to think that there is a bug.
 */
export const NoOrusAgents = memo<{ children: ReactNode }>(function NoOrusAgents({ children }) {
  const {
    user,
    permissions: { type },
  } = useSession()

  if (user && type === 'platform') {
    assert('email' in user, 'email not found in orus agent user')
    return <OrusAgentBlocker email={user.email} />
  }

  return children
})

export const OrusAgentBlocker = memo<{ email: string }>(function OrusAgentBlocker({ email }): JSX.Element {
  const disconnect = useDisconnect()
  return (
    <Page>
      <ActionPageLayout
        body={
          <>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: mediumspace;
              `}
            >
              <Avatar icon="users-solid" size="80" />
              <TextContainer variant="body1">
                <Text>
                  Tu es sur l&apos;<strong>app client</strong>, mais connecté(e) avec un compte d&apos;agent Orus{' '}
                  <strong>{email}</strong>
                </Text>
                <Text>
                  Déconnecte toi, puis connecte toi avec un compte client pour voir des choses intéressantes ici, ou va
                  dans le backoffice.
                </Text>
              </TextContainer>
            </div>
          </>
        }
        actions={
          <>
            <Button variant="secondary" onClick={disconnect}>
              Me déconnecter
            </Button>
            <ButtonLink variant="primary" to="/bak/home">
              Aller sur le backoffice
            </ButtonLink>
          </>
        }
        maxWidth="sm"
      />
    </Page>
  )
})
